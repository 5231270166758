// scss-lint:disable all
// Core Admin Variables

$enable-sidebar-nav-rounded:          false !default;

$border-color:                        $gray-200 !default;
$layout-transition-speed:             .25s !default;

// Social Colors

$facebook:                            #3b5998 !default;
$twitter:                             #00aced !default;
$linkedin:                            #4875b4 !default;
$google-plus:                         #d34836 !default;
$flickr:                              #ff0084 !default;
$tumblr:                              #32506d !default;
$xing:                                #026466 !default;
$github:                              #4183c4 !default;
$html5:                               #e34f26 !default;
$openid:                              #f78c40 !default;
$stack-overflow:                      #fe7a15 !default;
$youtube:                             #b00 !default;
$css3:                                #0170ba !default;
$dribbble:                            #ea4c89 !default;
$google-plus:                         #bb4b39 !default;
$instagram:                           #517fa4 !default;
$pinterest:                           #cb2027 !default;
$vk:                                  #45668e !default;
$yahoo:                               #400191 !default;
$behance:                             #1769ff !default;
$dropbox:                             #007ee5 !default;
$reddit:                              #ff4500 !default;
$spotify:                             #7ab800 !default;
$vine:                                #00bf8f !default;
$foursquare:                          #1073af !default;
$vimeo:                               #aad450 !default;

// Navbar

$navbar-height:                       55px !default;
$navbar-bg:                           #fff !default;
$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
)  !default;
$navbar-brand-width:                  155px !default;
$navbar-brand-bg:                     #fff !default;
$navbar-brand-logo:                   url('/img/refigurator-logo.jpg') !default;
$navbar-brand-logo-size:              70px auto !default;
$navbar-brand-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$navbar-brand-minimized-width:        50px !default;
$navbar-brand-minimized-bg:           $navbar-brand-bg !default;
$navbar-brand-minimized-logo:         url('/img/floorsoft-logo-symbol.png') !default;
$navbar-brand-minimized-logo-size:    24px !default;
$navbar-brand-minimized-border:       $navbar-brand-border !default;

$navbar-color:                        $gray-600 !default;
$navbar-hover-color:                  $gray-800 !default;
$navbar-active-color:                 $gray-800 !default;
$navbar-disabled-color:               $gray-300 !default;

$navbar-toggler-icon:                 str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-toggler-icon-hover:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;

// Sidebar

$sidebar-width:                       200px !default;
$sidebar-padding:                     0 !default;
$sidebar-minimized-width:             50px !default;
$sidebar-minimized-height:            $sidebar-minimized-width !default;
$sidebar-compact-width:               150px !default;
$sidebar-compact-height:              $sidebar-compact-width !default;
$sidebar-color:                       #fff !default;
$sidebar-bg:                          $gray-800 !default;
$sidebar-borders:                     none !default;
$mobile-sidebar-width:                220px !default;

// Sidebar Header

$sidebar-header-height:               auto !default;
$sidebar-header-bg:                   rgba(0,0,0,.2) !default;
$sidebar-header-padding-y:            .75rem !default;
$sidebar-header-padding-x:            1rem !default;

// Sidebar Form

$sidebar-form-border:                 0 !default;
$sidebar-form-bg:                     darken($sidebar-bg,10%) !default;
$sidebar-form-color:                  #fff !default;
$sidebar-form-placeholder-color:      rgba(255,255,255,.7) !default;

// Sidebar Navigation

$sidebar-nav-color:                   #fff !default;
$sidebar-nav-title-padding-y:         .75rem !default;
$sidebar-nav-title-padding-x:         1rem !default;
$sidebar-nav-title-color:             $gray-200 !default;
$sidebar-nav-link-padding-y:          .75rem !default;
$sidebar-nav-link-padding-x:          1rem !default;
$sidebar-nav-link-color:              #fff !default;
$sidebar-nav-link-bg:                 transparent !default;
$sidebar-nav-link-icon-color:         $gray-600 !default;
$sidebar-nav-link-borders:            0 !default;

$sidebar-nav-link-hover-color:        #fff !default;
$sidebar-nav-link-hover-bg:           theme-color("primary") !default;
$sidebar-nav-link-hover-icon-color:   #fff !default;
$sidebar-nav-link-hover-borders:      0 !default;

$sidebar-nav-link-active-color:       #fff !default;
$sidebar-nav-link-active-bg:          lighten($sidebar-bg, 5%) !default;
$sidebar-nav-link-active-icon-color:  theme-color("primary") !default;
$sidebar-nav-link-active-borders:     0 !default;

$sidebar-nav-dropdown-color:          #fff !default;
$sidebar-nav-dropdown-bg:             rgba(0,0,0,.2) !default;
$sidebar-nav-dropdown-borders:        0 !default;
$sidebar-nav-dropdown-indicator-color:$gray-600 !default;
$sidebar-nav-dropdown-indicator:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-nav-dropdown-indicator-hover-color:$sidebar-nav-link-hover-color;
$sidebar-nav-dropdown-indicator-hover:str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Sidebar Footer

$sidebar-footer-height:               auto !default;
$sidebar-footer-bg:                   rgba(0,0,0,.2) !default;
$sidebar-footer-padding-y:            .75rem !default;
$sidebar-footer-padding-x:            1rem !default;
$sidebar-footer-borders:              0 !default;

// Sidebar Minimizer

$sidebar-minimizer-height:            50px !default;
$sidebar-minimizer-bg:                rgba(0,0,0,.2) !default;
$sidebar-minimizer-borders:           0 !default;
$sidebar-minimizer-indicator-color:   $gray-600 !default;
$sidebar-minimizer-indicator:         str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-minimizer-hover-bg:          rgba(0,0,0,.3) !default;
$sidebar-minimizer-hover-indicator-color:$sidebar-nav-link-hover-color !default;
$sidebar-minimizer-hover-indicator:   str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;


// Top Navigation

$top-nav-bg:                          #fff !default;
$top-nav-color:                       $body-color !default;
$top-nav-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;
$top-nav-ul-borders: (
  all: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$top-nav-hover-color:                 #fff !default;
$top-nav-hover-bg:                    theme-color("primary") !default;
$top-nav-active-color:                #fff !default;
$top-nav-active-bg:                   theme-color("primary") !default;
$top-nav-height:                      $navbar-height - 15px !default;

// Breadcrumb
$breadcrumb-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

// Aside

$aside-menu-width:                    250px !default;
$aside-menu-color:                    $gray-800 !default;
$aside-menu-bg:                       #fff !default;
$aside-menu-borders: (
  left: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$aside-menu-nav-padding-y:            .75rem !default;
$aside-menu-nav-padding-x:            1rem !default;

// Footer

$footer-height:                       50px !default;
$footer-bg:                           $gray-100 !default;
$footer-color:                        $body-color !default;
$footer-borders: (
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

// Cards

$card-icon-bg:                        transparent !default;
$card-icon-color:                     $body-color !default;
